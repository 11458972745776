import * as types from "./actionsTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";
import * as authApi from "../api/authApi";

export const login = (email, password) => async (dispatch) => {
  const credentials = {
    Email: email,
    Password: password,
  };
  dispatch(beginApiCall());

  authApi
    .login(credentials)
    .then((res) => {
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: {
          auth: res,
        },
      });
      toast.success("Welcome " + res.Auth.Customer);
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("telematiqs-auth");
  localStorage.removeItem("telematiqs-token");
  dispatch({
    type: types.LOGOUT,
  });
};

export const getAccount = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  authApi
    .getAccount(auth, auth.user.CustomerId)
    .then((account) => {
      dispatch({
        type: types.LOAD_ACCOUNT_SUCCESS,
        payload: {
          account: account,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
