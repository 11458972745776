import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

// Redux
import { connect, useDispatch } from "react-redux";
import {
    getModemrequestsByDevice,
    saveModemRequest,
    deleteModemRequest,
    updateModemRequest,
} from "../../../actions/deviceModemRequestActions";

// Components
import RequestModal from "./RequestModal";
import Spinner from "../../common/Spinner";
import Confirmation from "../../common/Confirmation";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";


// Styling
import { Button, Table } from "react-bootstrap";

const DeviceRequestsPane = ({
    auth,
    device,
    modemRequests,
    apiCallsInProgress,
    isLoading,
}) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState({});
    const [Request, setRequest] = useState({
        Command: "",
        CommandType: 20,
    });

    useEffect(() => {
        RefreshRequest();
    }, []);

    const RefreshRequest = () => {
        if (apiCallsInProgress == 0) {
            dispatch(getModemrequestsByDevice(auth, device.IMEI));
        }
    };

    const selectRequest = (e) => {
        const request = modemRequests.find((item) => item.Id === e);
        setSelectedRequest(request);
        setRequest({
            Id: request.Id,
            IMEI: request.IMEI,
            Command: request.Command,
            CommandType: request.CommandType,
        });
    };

    const removeRequestHandler = (modemRequest) => {
        dispatch(deleteModemRequest(auth, modemRequest.Id));
        setShow(false);
    };


    const saveRequestHandler = (e) => {
        e.preventDefault();
        if (Request.Id) {
            dispatch(updateModemRequest(auth, device.IMEI, Request));
        } else {
            dispatch(saveModemRequest(auth, device.IMEI, Request));
        }
        setShow(false);
        setRequest({ Command: "", CommandType: 20 });
    };


    const showModalHandler = (e) => {
        setShow(true);
        selectRequest(e);
    }

    const isViewer = auth.user.Role === "Viewer";
    const reqs = [...modemRequests].sort(function (a, b) { return new Date(b.Created) - new Date(a.Created); })
    return (
        <>
            {isLoading | (apiCallsInProgress > 0) ? (
                <Spinner />
            ) : (
                <>
                    <div className="request-actions">
                        <Button
                            variant="light"
                            onClick={RefreshRequest}
                            style={{ float: "right" }}
                        >
                            ⟳
                        </Button>
                        {!isViewer && (
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setRequest({ Command: "", CommandType: 20 });
                                    setShow(true);
                                }}
                            >
                                Add Request
                            </Button>
                        )}
                    </div>
                    <br />
                    <Table hover striped responsive>
                        <thead className="thead-inverse">
                            <tr>
                                <th>Command</th>
                                <th>Created</th>
                                <th>Executed</th>
                                <th>CommandType</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody style={{ wordBreak: "break-all" }}>
                            {reqs.map((Modemrequest) => {

                                    // const isViewer = auth.user.Role === "Viewer";

                                    return (
                                        <tr
                                            className="table-row"
                                            key={Modemrequest.Id}
                                        >
                                            <td>{Modemrequest.Command}</td>
                                            <td className="timeStamp-table-cell">
                                                {moment(Modemrequest.Created).format(
                                                    "DD-MM-YYYY HH:mm:ss"
                                                )}
                                            </td>
                                            <td>
                                                {Modemrequest.Executed != null
                                                    ? moment(Modemrequest.Executed).format(
                                                        "DD-MM-YYYY HH:mm:ss"
                                                    )
                                                    : "Not yet"}
                                            </td>
                                            <td >{Modemrequest.CommandType}</td>
                                            <td>
                                                {/* {!isViewer && ( */}
                                                <Button className="shadow-sm border-secondary" variant="light"
                                                    onClick={() => {
                                                        showModalHandler(Modemrequest.Id)
                                                    }}>
                                                    <img
                                                        src={EditIcon}
                                                        alt="Edit"
                                                    />
                                                </Button>
                                                {/* )} */}
                                            </td>
                                            <td>
                                                {/* {!isViewer && ( */}
                                                <Button className="shadow-sm border-secondary" variant="light">
                                                    <Confirmation
                                                        buttonText={
                                                            <img
                                                                src={DeleteIcon}
                                                                alt="Delete"
                                                            />
                                                        }
                                                        description={`Are you sure you want to remove this modem request?`}
                                                        onConfirm={() => removeRequestHandler(Modemrequest)}
                                                    />
                                                </Button>
                                                {/* )} */}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                    {
                        selectedRequest && (
                            <RequestModal
                                device={device}
                                isLoading={isLoading}
                                show={show}
                                setShow={setShow}
                                request={Request}
                                saveRequestHandler={saveRequestHandler}
                                setRequest={setRequest}
                            />
                        )
                    }
                </>
            )}
        </>
    );
};

DeviceRequestsPane.propTypes = {
    auth: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    modemRequests: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        device: state.deviceDetails.device,
        modemRequests: state.deviceDetails.modemRequests,
        apiCallsInProgress: state.apiCallsInProgress,
        isLoading: state.deviceDetails.isLoading,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRequestsPane);
