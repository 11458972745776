import React, { useState, useEffect } from "react";
import { Table, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SortButton from "../../common/SortButton";
import { SortedTable } from "../../common/SortedTable";
import { useContextMenu } from "react-contexify";
import moment from "moment";
import DownloadIcon from "../../common/icons/downloadicon.svg";
import { handleError } from "../../../api/apiUtils";

const TachoSelectedObjectList = ({
  auth,
  tachoObjects,
  downloadFile
}) => {
  const { show } = useContextMenu({ id: "TachoSelectedObjectList" })
  const { list, requestSort, sortConfig } = SortedTable(tachoObjects);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    const defaultStartDate = moment().subtract(3, 'M').format('YYYY-MM-DD');
    setStartDate(defaultStartDate);
    filterData(defaultStartDate, 'start-date');

    const defaultEndDate = moment().format('YYYY-MM-DD');
    setEndDate(defaultEndDate);
    filterData(defaultEndDate, 'end-date');
  }, []);

  useEffect(() => {
    const filteredData = list.filter((item) => {
      const itemStartDate = moment(item.TimeStampFrom).format('YYYY-MM-DD');
      const itemEndDate = moment(item.TimeStampTo).format('YYYY-MM-DD');

      return moment(itemStartDate).isSameOrBefore(endDate) && moment(itemEndDate).isSameOrAfter(startDate);
    });
    handleDataFiltered(filteredData);
  }, [list, startDate, endDate]);

  const handleDataFiltered = (event) => {
    setFilteredData(event);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    if (name === 'start-date') {
      setStartDate(value);
    } else if (name === 'end-date') {
      setEndDate(value);
    }
    filterData();
  };

  const filterData = () => { };

  const handleOnChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [name]: checked,
    }));
  };

  const handleSelectAll = () => {
    const allChecked = Object.values(checkedItems).every((isChecked) => isChecked);
    const updatedCheckedItems = {};
    filteredData.forEach((tachoDownload) => {
      updatedCheckedItems[tachoDownload.Id] = !allChecked;
    });
    setCheckedItems(updatedCheckedItems);
  };

  const handleDownload = () => {
    const selectedCheckboxes = filteredData.filter((tachoDownload) => checkedItems[tachoDownload.Id]);

    selectedCheckboxes.forEach((e) => {

      let fileName = e.FileName;
      const baseUrl = process.env.API_URL + "/api/rtddata/";
      let uri = baseUrl + e.Id;

      fetch(uri, {
        headers: { Authorization: auth.accessToken },
      })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          const href = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => handleError(error));
    })
  };

  return (

    <div className="tachoSelectedObjectList rounded border border-light-subtle">
      <Row className="p-2">
        <Col>
          <Form.Label>Start date</Form.Label>
          <Form.Control
            type="date"
            id="start-date"
            name="start-date"
            value={startDate}
            onChange={handleDateChange}
          />
        </Col>
        <Col>
          <Form.Label>End date</Form.Label>
          <Form.Control
            type="date"
            id="end-date"
            name="end-date"
            value={endDate}
            onChange={handleDateChange}
          />
        </Col>
      </Row>
      <Table striped hover responsive style={{ fontSize: '14px' }}>
        <thead>
          <tr>
            <th>
              <input
                style={{ width: '16px', height: '16px' }}
                type="checkbox"
                className="checkbox-table-style m-0"
                checked={Object.values(checkedItems).every((isChecked) => isChecked)}
                onChange={handleSelectAll}
              >
              </input>
              <img
                src={DownloadIcon}
                style={{ margin: "-7px", marginLeft: "10px", marginBottom: "2px", width: "21px" }}
                alt="Download"
                onClick={handleDownload}
              ></img>
            </th>
            <th>
              License
              <SortButton
                name="LicensePlate"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Data From
              <SortButton
                name="FirstData"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Data Until
              <SortButton
                name="LastData"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Download
            </th>
          </tr>
        </thead>
        <tbody>
          {list === undefined ? (
            <tr>
              <td colSpan="15" style={{ textAlign: "center" }}>
                Select an Object
              </td>
            </tr>
          ) : (
            filteredData.map((tachoDownload) => {

              return (
                <tr
                  onContextMenu={(e) => {
                    show({ event: e, props: { tachoDownload: tachoDownload } });
                  }}
                  className="table-row"
                  id={tachoDownload.id}
                  key={tachoDownload.id}
                >
                  <td>
                    <input
                      type="checkbox"
                      style={{ margin: '0px', width: '17px', height: '17px' }}
                      className="checkbox-table-style"
                      name={tachoDownload.Id}
                      checked={checkedItems[tachoDownload.Id] || false}
                      onChange={handleOnChange}
                    />
                  </td>
                  <td>
                    {tachoDownload.LicensePlate == null ? (
                      <>
                        {" "}
                        <i>Name: </i> {tachoDownload.Name}{" "}
                      </>
                    ) : (
                      <>{tachoDownload.LicensePlate}</>
                    )}
                  </td>
                  <td>
                    {tachoDownload.TimeStampFrom !== "1990-01-01T00:00:00Z" ? (
                      <>
                        {moment(tachoDownload.TimeStampFrom).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </>
                    ) : (
                      <>Unknown Periode</>
                    )}
                  </td>
                  <td>
                    {tachoDownload.TimeStampFrom !== "1990-01-01T00:00:00Z" ? (
                      <>
                        {moment(tachoDownload.TimeStampTo).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </>
                    ) : (
                      <>Unknown Periode</>
                    )}
                  </td>
                  <td>
                    <img
                      src={DownloadIcon}
                      style={{ margin: "-7px" }}
                      alt="Download"
                      onClick={downloadFile}
                      id={tachoDownload.Id}
                      data-filename={tachoDownload.FileName}
                    ></img>

                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  )
}

TachoSelectedObjectList.propTypes = {
  auth: PropTypes.object.isRequired,
  tachoObjects: PropTypes.array.isRequired,
  downloadFile: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    tachoObjects: state.tachoObjects,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TachoSelectedObjectList);