import {handleResponse, handleError} from "./apiUtils";

const baseUrl = process.env.API_URL + "/api/rtddata/";

export const getTachoDownloadById = (auth, id) => {
    return fetch(baseUrl + id, {
        headers: {Authorization: auth.accessToken},
    })
        .then(handleResponse)
        .catch(handleError);
};

export function GetTachoDownloads(auth, dwnltype) {
    return fetch(baseUrl + "?type=" + dwnltype, {
        headers: {Authorization: auth.accessToken},
    })
        .then(handleResponse)
        .catch(handleError);
}

export function GetSearchResults(auth, searchTerm, filter, type, customerId = null) {
    return customerId === "null"
        ? fetch(`${baseUrl}search/${searchTerm}/${filter}/?type=${type}`,
            {
                headers: {Authorization: auth.accessToken},
            })
            .then(handleResponse)
            .catch(handleError)
        : fetch(`${baseUrl}search/${searchTerm}/${filter}/${customerId}/?type=${type}`,
            {
                headers: {Authorization: auth.accessToken},
            })
            .then(handleResponse)
            .catch(handleError);
}

export function GetTachoByCustomer(auth, customerId,type) {
    return fetch(`${baseUrl}customer/${customerId}/?type=${type}`, {
        headers: {Authorization: auth.accessToken},
    })
        .then(handleResponse)
        .catch(handleError);
}

export function GetTotalRowsCount(auth, type) {
    return fetch(`${baseUrl}all/?type=${type}`, {
        headers: {Authorization: auth.accessToken},
    })
        .then(handleResponse)
        .catch(handleError);
}
