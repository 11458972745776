import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import * as Yup from 'yup';

// Redux
import { useDispatch, connect } from "react-redux";

import { saveCustomer } from "../../../actions/customerActions";

// Styling
import { Form, Button, Modal } from "react-bootstrap";
import Countries from "../../../util/Countries";

const CreateCustomer = ({ auth, show, setShow, features, customers, rtdIntegrations }) => {
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();

    const initialValues = {
        name: "",
        country: "Netherlands",
        start: moment().format("YYYY-MM-DD"),
        isReseller: false,
        resellerId: auth.user.CustomerId != null ? auth.user.CustomerId : "",
        features: [],
        RTDInterval: 15,
        RTDIntegration: false,
        RTDIntegrationId: "",
        RTDIntCustId: ""
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Must be at least 3 characters")
            .max(25, "Must be 25 characters or less")
            .required("Required"),
        country: Yup.string()
            .oneOf(
                Countries.map((country) => country.name),
                "Invalid Country"
            )
            .required("Required"),
        start: Yup.date()
            .required("Required"),
        isReseller: Yup.boolean(),
        resellerId: Yup.string()
            .when("isReseller", {
                is: () => true,
                then: (schema) => (schema).required("Required") //Validation of id. (from customer to an id defined)
            }),
        features: Yup.array()
            .of(
                Yup.string()
                    .oneOf(
                        features.map((feature) => feature.Id),
                        "Invalid Feature"
                    )
                    .required("Required")
            ),
        RTDInterval: Yup.number()
            .min(1, "Must be at least 1")
            .max(60, "Must be 60 or less")
            .required("Required"),
        RTDIntegration: Yup.boolean(),
        RTDIntegrationId: Yup.string()
            .nullable()
            .when("RTDIntegration", {
                is: (value) => value == true,
                then: () => Yup.string().required("Required")
                    .test(
                        "RTDIntegrationId",
                        "Rtd Integration Id is required",
                        (value) => value && value !== "--"
                    )
            }),
        RTDIntCustId: Yup.string()
            .nullable()
            .when("RTDIntegration", {
                is: (value) => value == true,
                then: () => Yup.string().required("Required")
            })
    });

    const resellers = customers.filter((res) => res.IsReseller === true);

    const onSubmit = (values) => {
        console.log(values.isReseller)
        dispatch(saveCustomer(auth, values));
        setShow(false);
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema: validationSchema
    });

    // Check if the user role is Viewer and being able to only see their Resellers
    const isViewer = auth.user.Role === "Viewer";

    return (
        <div className="create-customer">
            {!isViewer && (
                <Button className="create-object-button" onClick={handleShow}>
                    New Customer
                </Button>
            )}
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Add Customer</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {({ errors, touched, handleSubmit, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className="text-left">
                                <Form.Label>Name</Form.Label>
                                <Field
                                    name="name"
                                    type="text"
                                    className={errors.name && touched.name ? "form-control is-invalid" : "form-control"}
                                    placeholder="Customer Name"
                                />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />

                                <Form.Label>Country</Form.Label>
                                <Field
                                    as="select"
                                    name="country"
                                    className={errors.country && touched.country ? "form-control is-invalid" : "form-control"}
                                >
                                    {Countries.map((country) => {
                                        return (
                                            <option key={country.code} value={country.name}>
                                                {country.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage name="country" component="div" className="invalid-feedback" />

                                <Form.Label>Start Date</Form.Label>
                                <Field
                                    name="start"
                                    type="date"
                                    className={errors.start && touched.start ? "form-control is-invalid" : "form-control"}
                                />
                                <ErrorMessage name="start" component="div" className="invalid-feedback" />

                                {auth.user.Role === "Admin" && (
                                    <>
                                        <Form.Label>Reseller</Form.Label>
                                        <Field
                                            type="checkbox"
                                            valid="true"
                                            name="isReseller"
                                            className={errors.isReseller && touched.isReseller ? "form-check-input is-invalid" : "form-check-input"}
                                            placeholder="isReseller"
                                        />
                                        <ErrorMessage name="isReseller" component="div" className="invalid-feedback" />
                                        <br></br>
                                        <Form.Label>Reseller Name</Form.Label>
                                        <Field
                                            as="select"
                                            name="resellerId"
                                            className={`form-control ${touched.resellerId && errors.resellerId ? "is-invalid" : ""}`}
                                        >
                                            <option>--{" "}</option>
                                            {resellers.map((reseller) => {
                                                return (
                                                    <option key={reseller.Id} value={reseller.Id}>
                                                        {reseller.Name}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                        <ErrorMessage name="resellerId" component="div" className="invalid-feedback" />
                                    </>
                                )}

                                <Form.Label>Features</Form.Label>
                                <Field
                                    as="select"
                                    multiple
                                    name="features"
                                    className={errors.features && touched.features ? "form-control is-invalid" : "form-control"}
                                >
                                    {features.map((feature) => {
                                        return (
                                            <option key={feature.Id} value={feature.Id}>
                                                {feature.Name}
                                            </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage name="features" component="div" className="invalid-feedback" />

                                <Form.Label>RTDUpload Interval (2 - 60 Days)</Form.Label>
                                <Field
                                    name="RTDInterval"
                                    type="number"
                                    className={errors.RTDInterval && touched.RTDInterval ? "form-control is-invalid" : "form-control"}
                                    placeholder="RTDUpload Interval"
                                />
                                <ErrorMessage name="RTDInterval" component="div" className="invalid-feedback" />

                                <Form.Label>RTD Integration</Form.Label>
                                <Field
                                    type="checkbox"
                                    name="RTDIntegration"
                                    className={errors.RTDIntegration && touched.RTDIntegration ? "form-check-input is-invalid" : "form-check-input"}
                                />
                                <ErrorMessage name="RTDIntegration" component="div" className="invalid-feedback" />
                                <br></br>

                                {values.RTDIntegration && (
                                    <>
                                        <Form.Label>RTD Integration Types{" "}</Form.Label>
                                        <Field
                                            as="select"
                                            name="RTDIntegrationId"
                                            className={errors.RTDIntegrationId && touched.RTDIntegrationId ? "form-control is-invalid" : "form-control"}
                                        >
                                            <option>--</option>
                                            {rtdIntegrations.map((integration) => {
                                                return (
                                                    <option key={integration.Id} value={integration.Id}>
                                                        {integration.Name}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                        <ErrorMessage name="RTDIntegrationId" component="div" className="invalid-feedback" />
                                        
                                        <Form.Label>RTD Customer Integration</Form.Label>
                                        <Field
                                            name="RTDIntCustId"
                                            type="text"
                                            className={errors.RTDIntCustId && touched.RTDIntCustId ? "form-control is-invalid" : "form-control"}
                                            placeholder="RTD Customer Integration"
                                        />
                                        <ErrorMessage name="RTDIntCustId" component="div" className="invalid-feedback" />
                                    </>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

CreateCustomer.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    features: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    rtdIntegrations: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        features: state.features,
        customers: state.customers,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomer);
