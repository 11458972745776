import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SortButton from "../../common/SortButton";
import { SortedTable } from "../../common/SortedTable";
import { useContextMenu } from "react-contexify";
import moment from "moment";

const TachoObjectList = ({
  auth,
  tachoDownloads,
  onCheckboxChange
}) => {
  const {show} = useContextMenu({id: "TachoObjectList"})
  const { list, requestSort, sortConfig } = SortedTable(tachoDownloads);

  const uniqueObjects = Object.values(
    list.reduce((result, item) => {
      if (!(item.LicensePlate in result) || new Date(item.TimeStampTo) > new Date(result[item.LicensePlate].TimeStampTo)) {
        result[item.LicensePlate] = item;
      }
      return result;
    }, {})
  );

  const handleCheckboxToggle = (checkboxValue) => {
    onCheckboxChange(checkboxValue);
  };

  return (
    <div>
      <div className="objectOverviewList rounded border border-light-subtle">
        <table className="table table-striped table-hover" style={{ fontSize: '14px' }}>
          <thead>
            <tr>
              <th>
                <input 
                  style={{ width: '16px', height: '16px' }} 
                  type="checkbox" 
                  className="checkbox-table-style m-0" 
                  checked={true} 
                  disabled
                />
              </th>
              <th>
                License
                <SortButton
                  name="LicensePlate"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Card Number
                <SortButton
                  name="CardNumber"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Gen
                <SortButton
                  name="GenType"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              {auth.user.IsReseller && (
                <th>
                  IMEI
                  <SortButton
                    name="IMEINumber"
                    requestSort={requestSort}
                    sortConfig={sortConfig}
                  />
                </th>
              )}
              <th>
                Last Event
                <SortButton
                  name="LastEvent"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Data Until
                <SortButton
                  name="LastData"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Next RDL
                <SortButton
                  name="NextRDL"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Days Left
                <SortButton
                  name="Law"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {list === undefined ? (
              <tr>
                <td colSpan="15" className="text-center">
                  No tacho downloads found
                </td>
              </tr>
            ) : (
              uniqueObjects.map((tachoDownload) => {

                const CurrentDate = moment(); 
                const NextDate = moment(tachoDownload.TimeStampTo).add(90, 'd');
                const CalculateDate = NextDate.diff(CurrentDate, 'days');
                const DaysLeft = CalculateDate / (60*60*24*1000);

                return (
                  <tr 
                  onContextMenu={(e)=>{
                    show({event: e, props: {tachoDownload: tachoDownload}});
                  }}
                  className= "table-row"
                  id={tachoDownload.id}
                  key={tachoDownload.Id}
                  >
                    <td>
                      <input 
                        type="checkbox" 
                        style={{width: '17px', height: '17px' }} 
                        className="checkbox-table-style m-0"
                        name="check"
                        value={tachoDownload.LicensePlate || ''}
                        checked={tachoDownload.checked}
                        onChange={() => handleCheckboxToggle(tachoDownload.LicensePlate)}
                      />
                    </td>
                    <td>
                      {tachoDownload.LicensePlate == null ? (
                        <>
                          {" "}
                          <i>Name: </i> {tachoDownload.Name}{" "}
                        </>
                      ) : (
                        <>{tachoDownload.LicensePlate}</>
                      )}
                    </td>
                    <td>{tachoDownload.CardNumber}</td>
                    <td>1/2</td>
                      {auth.user.IsReseller && (
                        <td>{tachoDownload.IMEI}</td>
                      )}
                    <td>{moment().format("DD-MM-YYYY HH:mm")}</td>
                    <td>
                      {tachoDownload.TimeStampFrom !== "1990-01-01T00:00:00Z" ? (
                        <>
                          {moment(tachoDownload.TimeStampTo).format("DD-MM-YYYY HH:mm")}
                        </>
                      ) : (
                        <>Unknown Periode</>
                      )}
                    </td>
                    <td>
                    {tachoDownload.TimeStampFrom !== "1990-01-01T00:00:00Z" ? (
                        <>
                          {moment(tachoDownload.TimeStampTo).add(28, 'd').format("DD-MM-YYYY")}
                        </>
                      ) : (
                        <>Unknown Periode</>
                      )}
                    </td>
                    <td>
                    {tachoDownload.TimeStampFrom !== "1990-01-01T00:00:00Z" ? (
                        <>
                          {DaysLeft.toFixed(0)}
                        </>
                      ) : (
                        <>Unknown</>
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

TachoObjectList.propTypes = {
  auth: PropTypes.object.isRequired,
  tachoDownloads: PropTypes.array.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    tachoDownloads: state.tachoDownloads,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TachoObjectList);
