import React, {useState} from "react";
import PropTypes from "prop-types";

// Redux
import {connect, useDispatch} from "react-redux";
import UpdateAuthServer from "../components/UpdateAuthServerjs";
import {removeAuthServer, updateAuthServer} from "../../../actions/authServerActions";

// Components
import Confirmation from "../../common/Confirmation";
import {SortedTable} from "../../common/SortedTable";
import SortButton from "../../common/SortButton";
import {Button, Table} from "react-bootstrap";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";

const AuthServerList = ({auth, authServers}) => {
        const dispatch = useDispatch();
        const [showModal, setShowModal] = useState(false);
        const {list, requestSort, sortConfig} = SortedTable(authServers);
        const [selectedAuthServer, setSelectedAuthServer] = useState(null);

        const selectAuthServer = (e) => {
            setSelectedAuthServer(
                authServers.find((authServer) => {
                    return authServer.Id === e;
                })
            );
        };

        const removeAuthServerHandler = (authServer) => {
            dispatch(removeAuthServer(auth, authServer.Id));
        };

        const showModalHandler = (e) => {
            setShowModal(true);
            selectAuthServer(e);
        }

        const onSubmit = (values) => {
            dispatch(updateAuthServer(auth, selectedAuthServer.Id, values));
            setShowModal(false);
        };

        return (
            <div className="authServer">
                <Table responsive striped hover>
                    <thead>
                    <tr>
                        <th>
                            Customer
                            <SortButton
                                name="Customer"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Name
                            <SortButton
                                name="Name"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            IP
                            <SortButton
                                name="IP"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Device Port
                            <SortButton
                                name="Device Port"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Port
                            <SortButton
                                name="Port"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Username
                            <SortButton
                                name="Username"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Password
                            <SortButton
                                name="Password"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Status
                            <SortButton
                                name="Status"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Endpoint
                            <SortButton
                                name="Endpoint"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {list.length === 0 ? (
                        <tr>
                            <td colSpan="15" style={{textAlign: "center"}}>
                                No authServers found
                            </td>
                        </tr>
                    ) : (
                        list.map((authServer) => {
                            return (
                                <tr
                                    className="table-row"
                                    key={authServer.Id}
                                >
                                    <td>{authServer.Customer}</td>
                                    <td>{authServer.Name}</td>
                                    <td>{authServer.IP}</td>
                                    <td>{authServer.DevicePort}</td>
                                    <td>{authServer.Port}</td>
                                    <td>{authServer.Username}</td>
                                    <td>{authServer.Password}</td>
                                    <td>
                                        {authServer.Status === 1
                                            ? "Server Up & Running"
                                            : "Server Down"}
                                    </td>
                                    <td>{authServer.Endpoint}</td>
                                    <td>
                                        <Button className="shadow-sm border-secondary" variant="light"
                                                onClick={() => {
                                                    showModalHandler(authServer.Id)
                                                }}>
                                            <img
                                                src={EditIcon}
                                                alt="Edit"
                                            />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button className="shadow-sm border-secondary" variant="light">
                                            <Confirmation
                                                buttonText={
                                                    <img
                                                        src={DeleteIcon}
                                                        alt="Edit"
                                                    />
                                                }
                                                description={`Are you sure you want to remove this authServer?`}
                                                onConfirm={() => removeAuthServerHandler(authServer)}
                                            />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                    </tbody>
                </Table>
                {
                    selectedAuthServer && (
                        <UpdateAuthServer
                            show={showModal}
                            setShow={setShowModal}
                            onSubmit={onSubmit}
                            authServer={selectedAuthServer}
                        />
                    )
                }
            </div>
        );
    }
;

AuthServerList.propTypes = {
    auth: PropTypes.object.isRequired,
    authServers: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthServerList);
