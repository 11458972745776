import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/devices/";

export function getDevicesCount(auth) {
  return fetch(baseUrl + "all/", { headers: { Authorization: auth.accessToken } })
      .then(handleResponse)
      .catch(handleError);
}

export function getDeviceBatch(auth) {
  return fetch(baseUrl + "chunk/", {
    headers: {
      Authorization: auth.accessToken,
    },
  })
      .then(handleResponse)
      .catch(handleError);
}

export function getDeviceBatchFromId(auth,id) {
  return fetch(baseUrl + "chunk/" + id, {headers: {Authorization: auth.accessToken,}})
      .then(handleResponse)
      .catch(handleError);
}

export function getDevices(auth) {
  return fetch(baseUrl, {
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getDeviceById(auth, id) {
  return fetch(baseUrl + id, {
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRawmessagesByDevice(auth, imei) {
  return fetch(baseUrl + "rawmessages/" + imei, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRawmessagesByDeviceById(auth, imei, id) {
  return fetch(baseUrl + "rawmessages/" + imei + "/" + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getLastRawmessagesByDevice(auth, imei) {
  return fetch(baseUrl + "rawmessages/last/" + imei, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getDeviceState(auth, imei) {
  return fetch(baseUrl + "devicestate/" + imei, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getDevicesByCustomer(auth, customer) {
  return fetch(baseUrl + "customer/" + customer, {headers: {Authorization: auth.accessToken}})
      .then(handleResponse)
      .catch(handleError)
}

export function getDevicesFromSearch(auth, searchTerm, filter, customer = null) {
  return customer === "null" 
      ? fetch(`${baseUrl}search/${searchTerm}/${filter}`, {headers: {Authorization: auth.accessToken}})
        .then(handleResponse)
        .catch(handleError)
      : fetch(`${baseUrl}search/${searchTerm}/${filter}/${customer}`, {headers: {Authorization: auth.accessToken}})
          .then(handleResponse)
          .catch(handleError)
}

export function saveDeviceNote(auth, note) {
  return fetch(`${baseUrl}note/`, {    
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(note),
  })
  .then(handleResponse)
  .catch(handleError)
}
