import * as Yup from "yup";
export const validationSchema = Yup.object({
    customerId: Yup.string()
        .required("Required")
        .test("is-customerId", "Customer is required", (value) => value !== "--"),
    name: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters"),
    ip: Yup.string()
        .required("Required")
        .matches(
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            "Invalid IP Address, must be in format: X.X.X.X"
        ),
    devicePort: Yup.number()
        .required("Required")
        .min(1, "Must be at least 1")
        .max(65535, "Must be less than 65535"),
    port: Yup.number()
        .required("Required")
        .min(1, "Must be at least 1")
        .max(65535, "Must be less than 65535"),
    username: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters"),
    password: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters"),
    status: Yup.number()
        .required("Required")
        .nullable()
});

// import * as Yup from 'yup';

// const schema = Yup.object().shape({
//   isCompany: Yup.boolean(),
//   companyName: Yup.string().when('isCompany', {
//     is: true,
//     then: Yup.string().required('Company name is required'),
//     otherwise: Yup.string(),
//   }),
// });
