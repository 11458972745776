import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import { loadCanConfigs } from "../../actions/canConfigActions";

// Components
import CanConfigList from "./CanConfigList";
import CreateCanConfig from "./components/CreateCanConfig";
import Spinner from "../common/Spinner";
import SearchBar from "../common/SearchBar";

// Styling
import { Card } from "react-bootstrap";
import {fetchDeviceTypes} from "../../actions/deviceTypeActions";

const CANConfigPage = ({ auth, apiCallsInProgress, canConfigs }) => {
  const dispatch = useDispatch();
  const canList = [...canConfigs];
  const [searchResult, setSearchResult] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const filters = ["Protocol", "Brand", "Name", "Active"];
  const [show, setShow] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    dispatch(loadCanConfigs(auth));
    dispatch(fetchDeviceTypes(auth));
  };

  return (
    <div className="canconfigs">
      <h2 className="title">QTLibrarys</h2>
      <SearchBar
        filters={filters}
        setSearchResult={setSearchResult}
        searchResult={searchResult}
        all={canList}
        refresh={refresh}
        setSearchActive={setSearchActive}
      />
      <div className="create-object-div">
        <CreateCanConfig show={show} setShow={setShow} />
      </div>
      <br />
      {canList.length === 0 || apiCallsInProgress > 0 ? (
        <Spinner />
      ) : (
        <Card>
          {searchResult.length === 0 && searchActive == false ? (
            <CanConfigList canConfigs={canList} />
          ) : (
            <CanConfigList canConfigs={searchResult} />
          )}
        </Card>
      )}
    </div>
  );
};

CANConfigPage.propTypes = {
  auth: PropTypes.object.isRequired,
  canConfigs: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    canConfigs: state.canConfigs,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CANConfigPage);
