import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchResultsByCustomer,
  fetchSearchResults,
  fetchTachoDownloads,
} from "../../actions/tachoDownloadActions";
import PropTypes from "prop-types";
import { Card, Row, Col } from "react-bootstrap";
import * as tachoDownloadApi from "../../api/tachoDownloadApi";
import { fetchCustomers } from "../../actions/customerActions";
import TachoObjectList from "./components/TachoObjectList";
import TachoSelectedObjectList from "./components/TachoSelectedObjectList";
import { fetchTachoObjects } from "../../actions/tachoObjectActions";
import { apiCallError } from "../../actions/apiStatusActions";
import LazySearchBar from "../common/LazySearchBar";
import { handleError } from "../../api/apiUtils";

const TachoObjectPage = ({
  auth,
  customers,
  tachoDownloads,
  tachoObjects,
  apiCallsInProgress
}) => {
  const dwnltype = 1;
  const dispatch = useDispatch();
  const tachoDownloadList = [...tachoDownloads];
  const tachoObjectsList = [...tachoObjects];
  let filters =
    auth.user.IsReseller
      ? ["LicensePlate", "CardNumber", "IMEI"]
      : ["LicensePlate", "CardNumber"];
  const [resultCount, setResultCount] = useState(0);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    if (apiCallsInProgress === 0) {
      getCustomers(auth);

      tachoDownloadApi
        .GetTotalRowsCount(auth, dwnltype)
        .then((resultCount) => {
          setResultCount(resultCount);
        })
        .catch((error) => {
          apiCallError(error);
        });
      dispatch(fetchTachoDownloads(auth, dwnltype));
    }
  };

  const [licensePlates, setLicensePlates] = useState([]);
  const handleCheckboxChange = (checkboxValue) => {
    if (licensePlates.includes(checkboxValue)) {
      setLicensePlates(
        licensePlates.filter((value) => value !== checkboxValue)
      );
    } else {
      setLicensePlates([...licensePlates, checkboxValue]);
    }
  };

  useEffect(() => {
    dispatch(fetchTachoObjects(auth, licensePlates));
  }, [licensePlates]);

  const getCustomers = (auth) => {
    dispatch(fetchCustomers(auth));
  };

  const getSearchResults = (searchTerm, filter, customerId = null) => {
    dispatch(
      fetchSearchResults(auth, searchTerm, filter, dwnltype, customerId)
    );
  };

  const setSearchActive = () => {};

  const getTachoDownloadsByCustomer = (customer) => {
    dispatch(fetchResultsByCustomer(auth, customer, dwnltype));
  };

  const downloadFile = (e) => {
    let fileName = e.target.getAttribute("filename");
    const baseUrl = process.env.API_URL + "/api/rtddata/";
    let uri = baseUrl + e.target.id;

    fetch(uri, {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            const href = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => handleError(error));
};

  return (
    <div className="objectOverview">
      <h2 className="title">Object Overview</h2>
      <LazySearchBar
        filters={filters}
        refresh={refresh}
        customers={customers}
        resultCount={resultCount}
        getSearchResult={getSearchResults}
        setSearchActive={setSearchActive}
        apiCallsInProgress={apiCallsInProgress}
        getObjectByCustomer={getTachoDownloadsByCustomer}
      />
      <br />
      <Row>
        <Col>
          <Card>
            <TachoObjectList
              tachoDownloads={tachoDownloadList}
              onCheckboxChange={handleCheckboxChange}
            />
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <TachoSelectedObjectList
              tachoObjects={tachoObjectsList}
              downloadFile={downloadFile}
            />
          </Card>          
        </Col>
      </Row>
    </div>
  )
}

TachoObjectPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  tachoDownloads: PropTypes.array.isRequired,
  tachoObjects: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customers: state.customers,
    tachoDownloads: state.tachoDownloads,
    tachoObjects: state.tachoObjects,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TachoObjectPage);